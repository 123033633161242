import React from 'react'
import { graphql, Link } from 'gatsby'
import styled from 'styled-components'

import Layout from '../templates/layout'
import AboutText from '../components/AboutText'
import ProjectList from '../components/ProjectList'

const HomePage = ({ data: { datoCmsHome: { featuredProjects } }, location, pageContext }) => {
  return (
    <Layout location={location} pageContext={pageContext} isHomepage={true}>
      <AboutText/>
      <ProjectList projects={featuredProjects}/>
    </Layout>
  )
}

export const query = graphql`
  query HomePageQuery {
    datoCmsHome {
      featuredProjects {
        clientName
        displayName
        slug
        hoverImage {
          fluid(imgixParams: {
            h: "333",
            w: "500",
            ar: "1.5",
            fm: "jpg",
            auto: "compress,format",
            fit: "crop",
            crop: "edges"
          }) {
            ...GatsbyDatoCmsFluid_noBase64
          }
        }
      }
    }
  }
`

export default HomePage
